import { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '@link/portal/stores';
import { eticketPortal } from '@link/portal/services';
import { Dialog, Button, Input, message } from '@linktivity/link-ui';
import { getCurrentLocale, ud, EMAIL_REGEX } from '@link/portal/utils';
import styles from './otp.module.css';

const RESEND_DURATION = 60_000;
const INTERVAL_DURATION = 1_000;

const OTP: React.FC = () => {
  const { t } = useTranslation();
  const { supplierId, secret, bookingId, distributionId } = useParams();
  const intervalRef = useRef<number>();
  const registerRef = useRef(false);
  const { otp } = useStore();

  const [remainingSeconds, setRemainingSeconds] = useState(RESEND_DURATION);
  const [loading, setLoading] = useState({
    register: false,
    request: false,
    verify: false
  });
  const [initRequestOTP, setInitRequestOTP] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const validResend = useMemo(() => remainingSeconds <= 0, [remainingSeconds]);

  const countdown = useCallback(() => {
    window.clearInterval(intervalRef.current);
    setRemainingSeconds(RESEND_DURATION);
    intervalRef.current = window.setInterval(() => {
      setRemainingSeconds(prevSeconds =>
        Math.max(prevSeconds - INTERVAL_DURATION, 0)
      );
    }, INTERVAL_DURATION);
  }, []);

  const registerOTP = useCallback(() => {
    if (email) {
      if (EMAIL_REGEX.test(email)) {
        setLoading(prev => ({ ...prev, register: true }));
        eticketPortal
          .registerOTP({
            body: {
              supplierId,
              bookingId,
              distributionId,
              secret,
              ud,
              locale: getCurrentLocale(),
              email
            }
          })
          .then(res => {
            if (res) {
              registerRef.current = true;
              otp.updateRegisterOTP(false);
              otp.updateVerifyOTP(true);
              countdown();
            }
          })
          .finally(() => {
            setLoading(prev => ({ ...prev, register: false }));
          });
      } else {
        message.warning(t('views.otp.invalidEmail'));
      }
    } else {
      message.warning(t('views.otp.inputEmail'));
    }
  }, [email, bookingId, supplierId, distributionId, secret, otp, countdown, t]);

  const requestOTP = useCallback(() => {
    setLoading(prev => ({ ...prev, request: true }));
    setInitRequestOTP(false);
    if (registerRef.current) {
      eticketPortal
        .registerOTP({
          body: {
            supplierId,
            bookingId,
            distributionId,
            secret,
            ud,
            locale: getCurrentLocale(),
            email
          }
        })
        .then(res => {
          if (res) {
            message.success(
              t('views.otp.sendCode', {
                email
              }),
              10
            );
          }
        })
        .finally(() => {
          setLoading(prev => ({ ...prev, request: false }));
          countdown();
        });
    } else {
      eticketPortal
        .requestOTP({
          body: {
            supplierId,
            bookingId,
            distributionId,
            secret,
            ud,
            locale: getCurrentLocale()
          }
        })
        .then(res => {
          if (res?.maskedEmail) {
            message.success(
              t('views.otp.sendCode', {
                email: res.maskedEmail
              }),
              10
            );
          }
        })
        .finally(() => {
          setLoading(prev => ({ ...prev, request: false }));
          countdown();
        });
    }
  }, [bookingId, supplierId, distributionId, secret, countdown, email, t]);

  const verifyOTP = useCallback(() => {
    if (code) {
      setLoading(prev => ({ ...prev, verify: true }));
      eticketPortal
        .verifyOTP({
          body: {
            supplierId,
            bookingId,
            distributionId,
            secret,
            code,
            locale: getCurrentLocale()
          }
        })
        .then(res => {
          if (res) {
            otp.updateRequestOTP(false);
            location.reload();
          }
        })
        .finally(() => {
          setLoading(prev => ({ ...prev, verify: false }));
        });
    } else {
      message.warning(t('views.otp.inputPassword'));
    }
  }, [code, bookingId, supplierId, secret, distributionId, otp, t]);

  useEffect(() => {
    if (otp.requestOTP) {
      setInitRequestOTP(true);
    }
  }, [otp.requestOTP]);

  useEffect(() => {
    document.body.classList.toggle('showOTP', otp.showOTP);
  }, [otp.showOTP]);

  return (
    <div className={styles.otp}>
      <Dialog
        visible={otp.registerOTP}
        title={<p className={styles.header}>{t('views.otp.title')}</p>}
        className={styles.dialog}
      >
        <div className={styles.body}>
          <div className={styles.content}>
            <p className={styles.line}>
              {t('views.otp.tips1')}
              {t('views.otp.tips2')}
            </p>
            <Input
              type="email"
              value={email}
              placeholder={t('views.otp.inputEmail')}
              onChange={e => setEmail(e.target.value)}
              className={styles.input}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            onClick={registerOTP}
            loading={loading.register}
            className={styles.button}
          >
            {t('views.common.submit')}
          </Button>
        </div>
      </Dialog>

      <Dialog
        visible={otp.verifyOTP}
        title={<p className={styles.header}>{t('views.otp.title')}</p>}
        className={styles.dialog}
      >
        <div className={styles.body}>
          {initRequestOTP ? (
            <div className={styles.content}>
              <p className={styles.line}>{t('views.otp.tips1')}</p>
            </div>
          ) : (
            <div className={styles.content}>
              <p className={styles.line}>{t('views.otp.inputOTPCode')}</p>
              <Input
                type="text"
                value={code}
                inputMode="numeric"
                placeholder={t('views.otp.inputPassword')}
                onChange={e => setCode(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className={styles.footer}>
          {initRequestOTP ? (
            <Button
              onClick={requestOTP}
              loading={loading.request}
              className={styles.button}
            >
              {t('views.otp.sendOTP')}
            </Button>
          ) : (
            <>
              <Button
                onClick={requestOTP}
                variant="outlined"
                disabled={!validResend}
                loading={loading.request}
                className={styles.button}
              >
                {t('views.common.resend')}{' '}
                {!validResend && `(${Math.round(remainingSeconds / 1_000)}s)`}
              </Button>

              <Button
                onClick={verifyOTP}
                loading={loading.verify}
                className={styles.button}
              >
                {t('views.common.confirm')}
              </Button>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const ObserverOTP = observer(OTP);
export default ObserverOTP;
