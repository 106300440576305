import { Language } from './enum';

export const LANGUAGE_MAP = {
  en: Language.English,
  ja: Language.Japanese,
  ko: Language.Korean,
  zh: Language.Chinese,
  zt: Language.ChineseT
};

export const ETICKET_TITLE = 'eTicket portal';
